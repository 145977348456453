import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connectHighlight } from "react-instantsearch-core";

const HitHighlight = ({ highlight, attribute, hit, numOfLines }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  // const x = parsedHit.map((part, index) =>
  //   part.isHighlighted ? (
  //     <b key={index}>
  //       <u>{part.value}</u>
  //     </b>
  //   ) : (
  //     <span key={index}>{part.value}</span>
  //   )
  // );

  // const mapped = parsedHit?.reduce(
  //   (add, x, index) =>
  //     x.isHighlighted
  //       ? `<b key=${index}><u>${x.value}</u></b>`
  //       : `<span key=${index}>${x.value}</span>` + add,
  //   ""
  // );
  // console.log(mapped, x, parsedHit);
  // return (
  //   <span className="d-inline-block hello-wrodl">
  //     {parsedHit.map((part, index) =>
  //       part.isHighlighted ? (
  //         // <b key={index}>
  //         //   <u>{part.value}</u>
  //         // </b>

  //         <span
  //           className="d-inline-block"
  //           dangerouslySetInnerHTML={{
  //             __html: `<b key=${index}>
  //            <u>${part.value}</u>
  //        </b>`,
  //           }}
  //           key={index}
  //         >
  //           {/* <span  /> */}
  //         </span>
  //       ) : (
  //         // <span key={index}>{part.value}</span>
  //         <span
  //           className=""
  //           dangerouslySetInnerHTML={{ __html: part?.value + "ss" }}
  //           key={index}
  //         >
  //           {/* <span  /> */}
  //         </span>
  //       )
  //     )}
  //     {/* inner - <div dangerouslySetInnerHTML={{ __html: mapped }} />
  //     {mapped + "mPPED"} */}
  //   </span>
  // );

  if (numOfLines) {
    return (
      <>
        <div
          style={!showMore ? { "--num-of-lines": numOfLines } : {}}
          className="hide-text"
        >
          {parsedHit.map((part, index) =>
            part.isHighlighted ? (
              <span className="highlight-text" key={index}>{part.value}</span>
            ) : (
              <span className="fw-400" key={index}>{part.value}</span>
            )
          )}
        </div>
        <div
          onClick={() => {
            setShowMore((prev) => !prev);
          }}
          className="fc-cprimary pointer mt-3"
        >
          {!showMore ? t("showMore") : t("showLess")}
        </div>
      </>
    );
  }
  return parsedHit.map((part, index) =>
    part.isHighlighted ? (
      <span className="highlight-text" key={index}>{part.value}</span>
    ) : (
      <span  className="fw-400" key={index}>{part.value}</span>
    )
  );
};

export default connectHighlight(HitHighlight);
