import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import Typesense from "typesense";

let TYPESENSE_SERVER_CONFIG = {
  apiKey: "uKey5bP7PWQphxobLkwtTjtzQrlgpnje", // Be sure to use an API key that only allows searches, in production
  nodes: [
    {
      host: "dkmzb73oyjeg890rp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  connectionTimeoutSeconds: 1,
  numRetries: 8,
};

export const typesenseAdapter = new TypesenseInstantsearchAdapter({
  server: TYPESENSE_SERVER_CONFIG,
  additionalSearchParameters: {
    queryBy:
      "subject,subjectEn,issuedRulling,issuedRullingEn,courtRuled,courtRuledEn,CaseType,CaseTypeEn,caseNumber,caseNumberEn,Case_Number,Case_Type_ENU",
    numTypos: 3,
    typoTokensThreshold: 1,
    per_page: 25,
  },
});

const typesense = new Typesense.Client({
  nodes: [
    {
      host: "dkmzb73oyjeg890rp-1.a1.typesense.net",
      port: 443,
      protocol: "https",
    },
  ],
  apiKey: "uKey5bP7PWQphxobLkwtTjtzQrlgpnje", // Be sure to use an API key that only allows searches, in production
  connectionTimeoutSeconds: 2,
});

export default typesense;

export const searchClient = typesenseAdapter.searchClient;
