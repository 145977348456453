import React, { useContext, useEffect } from "react";
import { connectHits } from "react-instantsearch-core";
import styled from "styled-components";
import { MovieHit } from "../movieHit";
import { Pagination } from "react-instantsearch-dom";
import { AppContext } from "../../context/AppContext";

const HitsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

function MoviesHits({ hits }) {
  // console.log(hits);
  const {  setAppState } = useContext(AppContext);

  useEffect(() => {
    console.log("hits", hits);
    setAppState((prev) => ({
      ...prev,
      filteredDocs: hits,
    }));
  }, [hits]);
  return (
    <div className="">
      {/* {isLoading ? "Helllo" : "ladadads"} */}
      {hits
        // .filter((hit) => !hit?.indication)
        ?.map((hit) => (
          <MovieHit key={hit.ObjectID} hit={hit} />
        ))}
      {hits.length > 0 && (
        <div className="d-flex w-100 flex-1 mb-5 align-items-center justify-content-center">
          <Pagination />
        </div>
      )}
    </div>
  );
}

export default connectHits(MoviesHits);
