import React, { useContext } from "react";
import { connectHits } from "react-instantsearch-core";
import styled from "styled-components";
import MovieHighlightSidebar from "../movieHighlight/hitSidebar";
import { Link, useNavigate } from "react-router-dom";
import { routePaths } from "../../App";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Pagination } from "react-instantsearch-dom";
import { useTranslation } from "react-i18next";

function MoviesHitsSidebar() {
  const [show, setShow] = useState(false);
  const { appState, setAppState } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleSelectAll = (e) => {
    // localStorage.setItem("selected_doc", null);
    // localStorage.setItem("allDocsSelected", JSON.stringify(e?.target?.checked));

    setAppState((prev) => ({
      ...prev,
      allDocsSelected: e?.target?.checked,
      selectedDocs: null,
    }));
  };

  return (
    <div className="w-100">
      <div className="d-flex mb-1 mt-3 align-items-center">
        <Link to={routePaths.root}>
          <i class="bi bi-arrow-left fc-csecondary fw-700 fs-18 me-2 pointer"></i>
        </Link>
        <p className="ff-os m-0 fs-18 fw-700 fc-cprimary">
          {t("chatPageSidebarHeading")}
        </p>
      </div>
      {/* <p className="ff-os m-0 fw-700">[{hits?.length}]</p> */}
      <p className="ff-os m-0 fs-14 hello-greeting fw-600 my-2">
        {t("chatPageSidebarSubheading")}
      </p>
      <div class="form-check form-switch">
        <input
          class="form-check-input shadow-sm"
          type="checkbox"
          checked={appState?.allDocsSelected}
          id="flexSwitchCheckDefault"
          onChange={handleSelectAll}
        />
        <label
          class="form-check-label fs-14 fw-500"
          for="flexSwitchCheckDefault"
        >
          {t("selectAllDocMessage")}
        </label>
      </div>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-16">
            Start Chatting with {show?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You’ve selected a document to chat about. Would you like to continue?
        </Modal.Body>
        <Modal.Footer>
          <Button className="fs-14" variant="secondary" onClick={handleClose}>
            No, Choose Another Document
          </Button>
          <Button
            className="fs-14"
            variant="primary"
            onClick={() => {
              localStorage.setItem("selected_doc", JSON.stringify(show));
              navigate(routePaths.chatWithDoc);
              handleClose();
            }}
          >
            Yes, Start Chat
          </Button>
        </Modal.Footer>
      </Modal> */}
      <div className="d-flex flex-column mt-3 w-100">
        {appState?.filteredDocs.map((hit) => (
          <div
            className={`w-100 my-1  ${i18n?.language === "ar" ? "rtl" : ""}`}
          >
            <MovieHighlightSidebar
              hit={hit}
              attribute={i18n?.language === "ar" ? "subject" : "subjectEn"}
            />
          </div>
        ))}

        {/* <div className="d-inline-block">
          <Pagination />
        </div> */}
      </div>
    </div>
  );
}

export default connectHits(MoviesHitsSidebar);
