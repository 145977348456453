import React, { useContext } from "react";
import MovieHighlight from "../movieHighlight";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";

export function MovieHit(props) {
  const { hit } = props;
  const {
    setAppState,
    appState,
    docIdsArray,
    docs,
    addDocIfNotExists,
    removeDoc,
  } = useContext(AppContext);
  const dateParts = hit?.CreationDate?.split("/");
  const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  const { i18n } = useTranslation();
  return (
    <div
      className={`w-100 my-3 p-3 rounded shadow-2 border-cprimary pointer refined-docs${
        appState?.allDocsSelected || appState?.selectedDocs?.id === hit?.id
          ? "-active"
          : ""
      }`}
      onClick={() => {
        // localStorage.setItem("selected_doc", JSON.stringify(hit));
        // setAppState((prev) => ({
        //   ...prev,
        //   selectedDocs: hit,
        // }));
        if (docs?.has(hit)) {
          removeDoc(hit);
        } else {
          addDocIfNotExists(hit);
        }
      }}
    >
      {appState?.allDocsSelected || docs?.has(hit) ? (
        <i class="bi bi-check-circle fs-20 fc-cprimary"></i>
      ) : (
        <i class="bi bi-circle fs-20 fc-cprimary"></i>
      )}
      {/* <MoviePoster src={hit.image} alt={hit.title} /> */}
      <div
        className={`fs-18 mt-2 fc-cprimary fw-500 ${
          i18n?.language === "ar" ? "rtl" : ""
        }`}
      >
        {/* <MovieHighlight hit={hit} attribute="vote_average" />{" "} */}
        {hit?.Case_Number}
      </div>
      <div
        className={`text-left h2 font-weight-bold ${
          i18n?.language === "ar" ? "rtl" : ""
        }`}
      >
        {/* <MovieHighlight hit={hit} attribute="title" />{" "} */}
        <MovieHighlight
          hit={hit}
          attribute={i18n?.language === "ar" ? "subject" : "subjectEn"}
        />{" "}
      </div>

      <div className={`text-left ${i18n?.language === "ar" ? "rtl" : ""}`}>
        {/* <MovieHighlight hit={hit} attribute="overview" />{" "} */}
        {/* <MovieHighlight hit={hit} attribute="CreationDate" />{" "} */}
        <p>{formattedDate}</p>
      </div>

      <div
        className={`h6 mt-2 font-weight-bold ${
          i18n?.language === "ar" ? "rtl" : ""
        }`}
      >
        {/* <MovieHighlight hit={hit} attribute="vote_average" />{" "} */}
        <MovieHighlight
          hit={hit}
          attribute={
            i18n?.language === "ar" ? "issuedRulling" : "issuedRullingEn"
          }
          numOfLines={5}
        />{" "}
      </div>
      {/* <div className="h6 mt-2 font-weight-bold rtl">
        <MovieHighlight hit={hit} attribute="courtRuled" />{" "}
      </div>
      <div className="h6 mt-2 font-weight-bold rtl">
        <MovieHighlight hit={hit} attribute="issuedRulling" />{" "}
      </div> */}
    </div>
  );
}
