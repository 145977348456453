import { createContext, useEffect } from "react";
import { useState } from "react";
import { SIDEBAR_EXPANDED_WIDTH } from "../components/sidebar/sidebar";

export const AppContext = createContext();

export const intialContextValues = {
  sidebarWidth: 320,
  selectedDocs: new Set([]),
  allDocsSelected: false,
  loading: true,
  userData: null,
  currentProject: null,
  showCreateProjectModal: false,
  headerLoading: "header",
  appName: "LegalQ ai",
  currLang: true,
  filteredDocs: [],
};

const AppContextProvider = ({ children }) => {
  const [docs, setDocs] = useState(new Set());
  const [appState, setAppState] = useState({
    ...intialContextValues,
  });

  // Function to add an item only if it doesn't exist in the Set
  const addDocIfNotExists = (item) => {
    console.log('aadad')

    setDocs((prevItems) => {
      const updatedSet = new Set(prevItems); // Create a new Set from the current docs

      if (!updatedSet.has(item)) {
        updatedSet.add(item); // Add item only if it doesn't exist
        console.log(`${item} added to the Set.`);
      } else {
        console.log(`${item} already exists in the Set.`);
      }

      return updatedSet; // Update state with the new Set
    });
  };

  // Function to remove an item from the Set
  const removeDoc = (item) => {
    console.log('remofinfi')
    setDocs((prevItems) => {
      const updatedSet = new Set(prevItems);
      updatedSet.delete(item); // Remove the specific item
      return updatedSet;
    });
  };

  // Convert the Set to an array for rendering
  const docsArray = () => Array.from(docs);

  // Convert the Set to an array for rendering
  const docIdsArray = () => Array.from(docs)?.map(doc => Number(doc?.id));

  useEffect(() => {
    // fetch the selected project id instead
    let selected_doc = localStorage.getItem("selected_doc");
    if (selected_doc) {
      selected_doc = JSON.parse(selected_doc);
    }
    let allDocsSelected = localStorage.getItem("allDocsSelected");
    if (allDocsSelected) {
      allDocsSelected = JSON.parse(allDocsSelected);
    }

    setAppState((prev) => ({
      ...prev,
      selectedDocs: selected_doc,
      allDocsSelected,
    }));
  }, []);

  return (
    <AppContext.Provider
      value={{ appState, setAppState, addDocIfNotExists, removeDoc, docs,docIdsArray, setDocs, docsArray }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContextProvider };
