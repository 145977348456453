import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { AppContext } from "../context/AppContext";
import { useTranslation } from "react-i18next";

const CustomSearchBox = ({ currentRefinement, refine }) => {
  const { appState, setAppState } = useContext(AppContext);
  const { t } = useTranslation();
  const handleChange = (event) => {
    console.log(event.target.value);

    if (typeof event.target.value === typeof "hello") {
      // localStorage.setItem("searchQuery", searchQuery);
      refine(event.target.value);

      setAppState((prev) => ({
        ...prev,
        currentProject: {
          ...prev?.currentProject,
          searchQuery: event.target.value,
        },
      }));
    }
  };

  useEffect(() => {
    // if (appState?.currentProject?.searchQuery) {
    refine(appState?.currentProject?.searchQuery);
    // }
  }, []);

  return (
    // <div className="custom-searchbox col-10 mx-auto">
    <textarea
      className="ais-SearchBox-input p-3"
      value={currentRefinement}
      onChange={handleChange}
      placeholder={t("searchInputPlaceholder")}
      rows={4} // Adjust the number of rows as needed
      style={{ width: "100%" }} // Customize style as needed
    />
    // </div>
  );
};

export default connectSearchBox(CustomSearchBox);
