import React, { useContext } from "react";
import { useState } from "react";
import { connectHighlight } from "react-instantsearch-core";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";

const HitHighlightSidebar = ({ highlight, attribute, hit }) => {
  const { appState, docs, removeDoc, addDocIfNotExists } =
    useContext(AppContext);
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    attribute,
    hit,
  });

  return (
    <>
      <div
        onClick={() => {
          if (docs?.has(hit)) {
            removeDoc(hit);
          } else {
            addDocIfNotExists(hit);
          }
          // localStorage.setItem("selected_doc", JSON.stringify(hit));
          // setAppState((prev) => ({
          //   ...prev,
          //   selectedDocs: hit,
          // }));
        }}
        className={`m-0 mb-2 border p-2 rounded pointer border-cprimary refined-docs${
          appState?.allDocsSelected || docs?.has(hit) ? "-active" : ""
        }`}
      >
        <div className="d-flex w-100 justify-content-end">
          {appState?.allDocsSelected || docs?.has(hit) ? (
            <i class="bi bi-check-circle fs-20 fc-cprimary"></i>
          ) : (
            <i class="bi bi-circle fs-20 fc-cprimary"></i>
          )}
        </div>
        <p className="fs-14 fw-500 fc-cprimary m-0">{hit?.Case_Number}</p>

        {parsedHit.map((part, index) => (
          <span key={index} className="fs-14 fw-400">
            {part.value}
          </span>
        ))}
      </div>
    </>
  );
};

export default connectHighlight(HitHighlightSidebar);
